










































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AutocompleteInput from '@/components/common/AutocompleteInput.vue'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import { Action, Getter, State } from 'vuex-class'
import { Qualification, User } from '@/store/types'
import { GET_ALL_SYNONYMS } from '@/store/user/actions.type'
import { GET_OWN_QUALIFICATIONS, SET_OWN_QUALIFICATIONS } from '@/store/student/actions'
import InfoBox from '@/components/hszg/InfoBox.vue'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import SelectWithLevel from '@/components/forms/SelectWithLevel.vue'
import Rights, { STUDENT_OWN_VIEW } from '@/common/rights'
import { hasRight } from '@/utils/rights'
import QualificationsEditorForm from '@/components/forms/QualificationsEditorForm.vue'
import PreviewHandler from '@/components/common/PreviewHandler.vue'
@Component({
  components: { PreviewHandler, QualificationsEditorForm, SelectWithLevel, SubmitSuccess, InfoBox, AutocompleteInput }
})
export default class QualificationsEditor extends Vue {
  @State('currentScreenWidth') screenWidth: number

  @Action(GET_ALL_SYNONYMS)
  public getAllSynonyms: () => Promise<Array<Qualification>>

  @Action(GET_OWN_QUALIFICATIONS)
  public getOwnQualifications: () => Promise<Array<Qualification>>

  @Action(SET_OWN_QUALIFICATIONS)
  public setOwnQualifications: (params: Qualification[]) => Promise<Qualification[]>

  @Getter('userGetter') userGetter: User

  talentQualifications: Qualification[] = [{ value: '' }]
  qualificationOptions: Qualification[] = []

  loadingQualifications = true

  feedbackHeadline = ''
  feedbackInfo = ''
  responseState = false

  get md (): boolean {
    return this.screenWidth > bootstrapBreakPoints.md
  }

  get writeable (): boolean {
    return hasRight(Rights.STUDENT_OWN_QUALIFICATION_WRITE)
  }

  get readonly (): boolean {
    return hasRight(Rights.STUDENT_OWN_QUALIFICATION_READ)
  }

  get synonymsRead (): boolean {
    return hasRight(Rights.SYNONYMS_READ)
  }

  get synonymsWrite (): boolean {
    return hasRight(Rights.SYNONYM_WRITE)
  }

  get hasPreviewRight (): boolean {
    return hasRight(STUDENT_OWN_VIEW)
  }

  mounted (): void {
    this.loadOwnQualifications()
  }

  loadOwnQualifications (qualifications?: Qualification[]): void {
    if (qualifications) {
      this.talentQualifications = qualifications.map(qualification => {
        return { ...qualification, value: qualification.synonym ? qualification.synonym : '', text: qualification.synonym }
      })
    } else {
      this.loadingQualifications = true
      this.getOwnQualifications().then(data => {
        this.talentQualifications = data.map((qualification: Qualification) => {
          return { ...qualification, value: qualification.synonym ? qualification.synonym : '', text: qualification.synonym }
        })
      }).finally(() => {
        this.loadingQualifications = false
      })
    }
  }

  setQualifications (updatedValues: Qualification[]): void {
    this.talentQualifications = updatedValues.map<Qualification>((qualification: Qualification) => {
      qualification.synonym = qualification.value
      qualification.text = qualification.value
      return qualification
    })
  }

  saveQualifications (): void {
    this.loadingQualifications = true
    this.setOwnQualifications(this.talentQualifications).then(data => {
      this.feedbackHeadline = this.$t('info.save-success', { what: this.$i18n.tc('qualifications.qualification', this.talentQualifications.length) }).toString()
      this.responseState = true
      this.loadOwnQualifications(data)
    }).catch(error => {
      this.feedbackHeadline = error.status + ' ' + error.error
      this.feedbackInfo = error.message
      this.responseState = false
    }).finally(() => {
      this.loadingQualifications = false
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, !this.responseState)
    })
  }

  public showPreview (): void {
    this.saveQualifications()
    this.$nextTick(() => {
      const lang = this.$i18n.locale.toString()
      const routeData = this.$router.resolve({ name: 'talent-profile-preview', params: { id: this.userGetter.appUserId } })
      const target = window.location.origin + '/' + lang + routeData.href
      window.open(target, '_blank')
    })
  }
}
